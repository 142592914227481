import React, {
  useEffect,
  useReducer,
  useContext,
  useState,
  useCallback,
} from "react";

import Swal from "sweetalert2";

import styled from "styled-components";
import useWeb3 from "../hooks/useWeb3";
import { AppContext } from "../AppContext";

import { motion } from "framer-motion";

import { BUSD_TOKEN_ADDRESS } from "../config";

import ScreenBlocking from "./ScreenBlocking";
import "react-toastify/dist/ReactToastify.css";
import "../App.css";
import { IMAGE_BY_LEVEL } from "../config";

import withReactContent from "sweetalert2-react-content";

import { Text } from "./SwapBoard";

import useDAO from "../hooks/useDAO";

import BuyModal from "./Modal/BuyModal";
import IDONFT from "./IDONFT";

const MainWrapper = styled.div`
  // min-height: calc(100vh - 100px);
  div:first-child {
    flex-wrap: wrap;
    /* margin: -1rem; */
  }
  column-gap: 30px;
`;

const ChildItem = styled.div`
  width: calc(100% -20);
  padding: 0 0.5rem;
  /* margin: 5px 0; */

  @media (min-width: 768px) {
    width: 100%;
  }
  @media (min-width: 1024px) {
    width: calc(100% / 3.5);
  }
`;

export const BackToHome = styled.div`
  padding: 8px 10px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.02);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  display: inline-block;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.1);
  transition: 0.15s ease-out;
  svg {
    stroke: #3a3a3a;
    transition: 0.15s ease-out;
  }
  :hover {
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2);
    svg {
      stroke: #333;
    }
  }
`;

export const IconWrapper = styled.div`
  p {
    transition: 0.15s ease-in-out;
    /* opacity: 0; */
  }
  :hover {
    p {
      /* opacity: 1; */
    }
  }
  max-width: 100px;
`;

const variants = {
  hidden: {
    x: "20%",
    opacity: 0,
    transition: {
      duration: 0.2,
    },
  },
  visible: {
    x: "0",
    opacity: 1,
  },
};

const HeaderBackground = styled.div`
  width: 100%;
  height: 600px;
  background: url("/images/background.jpg") center 35% / cover no-repeat;
`;

const CONTENT = `By purchasing an NFT IDO card, you can participate in hundreds of IDO projects on Winery DAO. This is a perfect opportunity to be on the whitelist of new potential projects in the easiest way possible. Take advantage of this chance by purchasing NFT IDO right now!`;

const IDOMarket = ({ account }) => {
  const {
    web3,
    nftSell,
    isLoading: isMainLoading,
    setNftSell,
    listMyNft,
    loadMyNFT,
    idoNftSell,
    listIDOMyNft,
    setIdoNftSell,
    loadIDOMyNFT,
  } = useContext(AppContext);

  const { onApproveHandler, checkApprove } = useWeb3(account);

  const { buyNFT, buyIDONFT } = useDAO();

  const [isApproved, setApproved] = useState(false);

  const [isLoading, setLoading] = useState(false);

  const [isOpenModal, setOpenModal] = useState(false);

  const [infoSelected, setSelectedInfo] = useState(false);

  const toggleModal = () => {
    setOpenModal(!isOpenModal);
  };

  useEffect(() => {
    loadAllowance();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  // const loadOptionSell = async () => {
  //   setLoading(true);

  //   const [dataOptionSell, dataMyNFT] = await Promise.all([
  //     getListOptionSell(),
  //     getListMyNFT(account, web3),
  //   ]);

  //   if (dataOptionSell) {
  //     const parseMyNft = {};
  //     dataMyNFT.forEach((item) => {
  //       parseMyNft[item.level] = (parseMyNft[item?.level] || 0) + 1;
  //     });

  //     const newOption = dataOptionSell.map((item) => ({
  //       ...item,
  //       myNFT: parseMyNft[item.level] || 0,
  //     }));

  //     setNFTSell(newOption);
  //   }

  //   setLoading(false);
  // };

  const loadAllowance = async () => {
    const isApproved = await checkApprove(
      web3,
      BUSD_TOKEN_ADDRESS,
      18,
      process.env.REACT_APP_IDO_LAUNCHPAD_ADDRESS
    );

    if (isApproved) {
      setApproved(true);
    } else {
      setApproved(false);
    }
  };

  const onApprove = async () => {
    setLoading(true);

    const isSuccess = await onApproveHandler(
      web3,
      BUSD_TOKEN_ADDRESS,
      process.env.REACT_APP_IDO_LAUNCHPAD_ADDRESS
    );
    setApproved(isSuccess);

    setLoading(false);
  };

  const onBuy = async ({ index, refCode, price, level }) => {
    setLoading(true);

    const isSuccess = await buyIDONFT({
      index,
      account,
      refCode,
      web3,
      price,
      level,
      contract: process.env.REACT_APP_IDO_LAUNCHPAD_ADDRESS,
    });
    setLoading(false);

    if (isSuccess) {
      const newNftSell = [...idoNftSell];
      newNftSell[index].soldCount += 1;

      setIdoNftSell(newNftSell);
      loadIDOMyNFT();
      withReactContent(Swal).fire({
        imageUrl: "/mouse.svg",
        imageWidth: "auto",
        imageHeight: "auto",
        imageAlt: "Custom image",

        title: (
          <span style={{ color: "rgba(30, 147, 255, 1)" }}>
            Congratulations!
          </span>
        ),
        textColor: "green",
        html: (
          <span style={{ color: "rgb(128, 128, 128)", fontWeight: 400 }}>
            You have successfully Buy NFT{" "}
          </span>
        ),
        focusConfirm: false,
        confirmButtonText: "Continue",

        backdrop: `#e7edf599`,
      });
      toggleModal();
    } else {
      withReactContent(Swal).fire({
        imageUrl: "/error-face.png",
        imageWidth: "auto",
        imageHeight: "auto",
        imageAlt: "Custom image",

        title: <span style={{ color: "#ED1C51" }}> Something wrong...</span>,
        textColor: "green",
        html: (
          <span style={{ color: "rgb(128, 128, 128)", fontWeight: 400 }}>
            Something went wrong. Please try again!!!
          </span>
        ),
        focusConfirm: false,
        confirmButtonText: "Continue",

        backdrop: `#e7edf599`,
      });
    }
  };

  return (
    <>
      <motion.main
        variants={variants}
        initial="hidden"
        animate="visible"
        exit="hidden"
        className="my-3 position-relative"
      >
        <HeaderBackground className="d-flex align-items-center justify-content-center">
          <div>
            <Text
              color="#fff"
              fontFamily="Playfair"
              fontWeight="900"
              fontSize="62px"
              textTransform="uppercase"
              textAlign="center"
              mb="48px"
              className="ms-0"
            >
              IDO DAO Market{" "}
            </Text>
            <Text
              color="#fff"
              textAlign="center"
              fontFamily="Playfair"
              fontSize="28px"
              fontWeight="500"
              className="ms-0"
            >
              Buy NFT and be right now on the whitelist of hundreds of IDO
              projects
            </Text>
          </div>
        </HeaderBackground>
        <MainWrapper className="container" style={{ marginBottom: 10 }}>
          <div
            style={{
              marginTop: 20,
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Text
              color="#000"
              fontWeight="400"
              fontSize="24px"
              style={{ lineHeight: 1.4, width: "80%", marginTop: 20 }}
              textAlign="center"
              className="ms-0"
            >
              {CONTENT}
            </Text>
          </div>
        </MainWrapper>

        <MainWrapper className="container" style={{ marginBottom: 150 }}>
          <div
            className=" d-flex mb-4"
            style={{
              marginTop: 10,
              columnGap: 0,
              maxWidth: "80%",
              margin: "0 auto",
            }}
          >
            {idoNftSell.map((item, index) => {
              return (
                // <ChildItem key={item?.level + index} onClick={() => {}}>
                <IDONFT
                  isSell
                  isActive={false}
                  onApprove={onApprove}
                  isApproved={isApproved}
                  isLoading={isLoading}
                  info={{ ...item, level: item?.level }}
                  index={index}
                  disabledBuy={!!listIDOMyNft?.length}
                  onBuy={() => {
                    setSelectedInfo({
                      ...item,
                      level: item?.level,
                      index: index,
                    });
                    toggleModal();
                  }}
                />
                // </ChildItem>
              );
            })}
          </div>
        </MainWrapper>
      </motion.main>
      {isOpenModal && (
        <BuyModal
          title="Buy NFT"
          isActive={isOpenModal}
          info={infoSelected}
          imageUrl={"/images/package-ido.png"}
          price={+infoSelected.price}
          handleClose={toggleModal}
          index={+infoSelected.index}
          level={+infoSelected.level}
          onBuy={onBuy}
          isApproved={isApproved}
          onApprove={onApprove}
          type="ido"
        />
      )}
      <ScreenBlocking isLoading={isLoading || isMainLoading} />
    </>
  );
};

export default IDOMarket;
