import React from "react";
import styled from "styled-components";

import {
  IMAGE_BY_LEVEL,
  levelNameMapping,
  levelBackgroundMapping,
} from "../config";

export const formatTotalCoin = (num) => {
  let dollarUSLocale = Intl.NumberFormat("en-US");

  return dollarUSLocale.format(num);
};

const IDOSwapBoard = styled.div`
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  background: transparent;
  max-width: 368px;
  margin: 0 auto;
  overflow: hidden;
  width: 100%;
  position: relative;
  transition: 0.2s ease-out;
  margin-top: 70px;
  &:hover {
    transform: scale(1.02);
    color: red;
  }
`;

const BoardWrapper = styled.div`
  width: 100%;
  margin-top: 50px;
  cursor: pointer;
  transition: 0.2s ease-out;
  position: relative;
  &:hover {
    transform: scale(1.02);
  }
`;

export const Text = styled.p`
  margin-left: 8px;
  color: ${({ color }) => color ?? "#000"};
  font-weight: ${({ fontWeight }) => fontWeight ?? "700"};
  font-size: ${({ fontSize }) => fontSize ?? "34px"};
  ${({ textTransform }) => textTransform && `text-transform:${textTransform};`}
  ${({ fontFamily }) =>
    fontFamily === "Playfair" && "font-family: Playfair Display SC;"}
  line-height: ${({ lineHeight }) => lineHeight ?? 1};
  text-align: ${({ textAlign }) => textAlign ?? "start"};
  ${({ mb }) => mb && `margin-bottom: ${mb};`}
  ${({ truncate }) =>
    truncate &&
    `
    text-overflow:ellipsis;
    white-space:nowrap;
    overflow:hidden;
    width:150px;
    `}
    ${({ linear }) =>
    linear &&
    `
    background: linear-gradient(-90deg, rgb(136, 3, 47), rgb(238, 21, 66));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
    `}
`;

export const StyledButton = styled.button`
  --main-color: linear-gradient(214.02deg, #d71479 6.04%, #f87421 92.95%);
  text-decoration: none;
  outline: none;
  border: none;

  background: var(--main-color);
  color: #fff;
  text-transform: capitalize;
  font-weight: 600;
  padding: 1rem;
  transition: 0.1s ease-out;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 30px;
  font-size: ${({ fontSize }) => fontSize ?? "16px"};
  &.success {
    background: var(--main-color);
    border-radius: 10px;
  }
  :disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  &:hover {
    filter: brightness(1.1);
  }
`;

export const OutlineButton = styled(StyledButton)`
  border-radius: 30px;
  background: rgb(247, 248, 250);
  position: relative;
  color: #ed1c51;
  :hover {
    color: #fff;
  }
  :before {
    border-radius: 30px;
    position: absolute;
    content: "";
    background: linear-gradient(214.02deg, #d71479 6.04%, #f87421 92.95%);
    top: -2px;
    left: -2px;
    bottom: -2px;
    right: -2px;
    position: absolute;
    z-index: -1;
  }
`;

const InputTokenWrapper = styled.div`
  text-align: left;
  padding: 0.75rem;
  /* border: 1px solid rgba(196, 196, 196, 0.5); */
`;

export const Flex = styled.div`
  display: flex;
  ${({ alignItems }) => alignItems && `align-items:${alignItems};`}
  ${({ justifyContent }) =>
    justifyContent && `justify-content:${justifyContent};`}
  ${({ flexWrap }) => flexWrap && `flex-wrap:${flexWrap};`}
`;

export const GradientText = styled(Text)`
  background: -webkit-linear-gradient(214.02deg, #d71479 6.04%, #f87421 92.95%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const StyledNFTImg = styled.img`
  width: ${({ width }) => width ?? "100%"};
  height: auto;
  min-height: 200px;
  border-radius: 5px 5px 0 0;
`;

const NFTImage = styled(StyledNFTImg)`
  max-height: auto;
`;

const ButtonBuy = styled.div`
  background-color: white !important;
  padding: 10px;
  font-family: "Poppins";
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 45px;
  text-align: center;
  text-transform: uppercase;
  background-color: #ffffff;
  color: #ee1542;
  cursor: pointer;
`;

const IDONFT = ({
  isSell = false,
  isLoading,
  isApproved,
  onApprove,
  info,
  onBuy,
  index,
  isActive = false,
  disabledBuy,
  isNew = false,
}) => {
  const isTemp = index == 1;
  const onHandleBuy = () => {
    return onBuy();
  };

  const [hover, setHover] = React.useState(false);

  return (
    // <BoardWrapper
    //   onClick={
    //     disabledBuy || (info.soldCount + info.sendCount) / info.totalCount == 1
    //       ? null
    //       : onHandleBuy
    //   }
    // >
    <IDOSwapBoard
      style={{ background: levelBackgroundMapping(isTemp ? 8 : 6) }}
    >
      {isTemp && (
        <div
          onMouseEnter={() => {
            setHover(true);
          }}
          onMouseLeave={() => {
            setHover(false);
          }}
          // onClick={() => setHover(!hover)}
          style={{
            background: "#242323b5",
            position: "absolute",
            zIndex: 100,
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            display: "flex",
            opacity: hover ? "1" : "0",
          }}
        >
          <Text
            fontSize="20px"
            className="ms-0"
            fontWeight="900"
            color="#ffffff"
            textAlign="center"
            style={{ lineHeight: "23px !important", padding: "0 15px" }}
          >
            {/* Receive this NFT in Airdrop event */}
            These NFTs are exclusively given out as the upcoming event prizes{" "}
            <br /> Follow{" "}
            <a
              target="_blank"
              href="https://winery.gitbook.io/winery/welcome/contact-us/community"
            >
              our community
            </a>{" "}
            for the most up-to-date event information. <br />
            <span style={{ color: "#E44D6A", fontSize: "15px" }}>
              *These NFTs are given as rewards and not for sale.
            </span>
          </Text>
        </div>
      )}
      <NFTImage
        src={isTemp ? "/images/package-ido-1.png" : "/images/package-ido.png"}
      />
      {disabledBuy && !isTemp && (
        <img
          alt="images/reachlimit.png"
          style={{
            position: "absolute",
            top: -70,
            left: -44,
            zIndex: 1000,
            width: 220,
            transform: "rotateZ(349deg)",
          }}
          src={`/images/reachlimit.png`}
        />
      )}
      <InputTokenWrapper className="z-100  mt-2 p-0">
        <Flex
          justifyContent="space-between"
          alignItems="end"
          className="mb-2 px-3"
        >
          <Text
            fontSize="20px"
            className="ms-0"
            fontWeight="900"
            color="#ffffff"
            textTransform="uppercase"
          >{`Tier ${info.level}`}</Text>
          <Text
            color="#ffffff"
            fontSize="18px"
            fontWeight={500}
            className="ms-0"
          >
            {`${info.soldCount + info.sendCount}/${info.totalCount}`}
          </Text>
        </Flex>
        {/* <SwapLine justifyContent="center" className="my-2"></SwapLine> */}
        <Flex
          justifyContent="space-between"
          alignItems="end"
          className="ms-0 mt-3"
        >
          <div className="d-flex flex-row align-items-center justify-content-between w-100 mt-3">
            {isTemp ? (
              // <Text
              //   fontSize="30px"
              //   className="ms-0"
              //   fontWeight="900"
              //   color="#fff"
              //   style={{ marginLeft: 0, marginBottom: 5 }}
              // >
              //   Airdrop
              // </Text>
              <>
                <Text
                  fontSize="26px"
                  className="ms-0"
                  fontWeight="600"
                  color="#fff"
                  textTransform="uppercase"
                  style={{ paddingLeft: "18px" }}
                >
                  White
                </Text>
                <Text
                  fontSize="20px"
                  className="ms-0"
                  fontWeight="900"
                  color="#fff"
                  style={{
                    background:
                      "linear-gradient(269.99deg, #88032F 3.87%, #EE1542 87.56%)",
                    borderRadius: "8px 8px 0px 0px",
                    padding: "13px",
                  }}
                >
                  Preparing
                </Text>
              </>
            ) : (
              <>
                {" "}
                {/* <Text
                  fontSize="14px"
                  color="white"
                  fontWeight={500}
                  style={{ marginLeft: 0, marginBottom: 5 }}
                >
                  Fixed price
                </Text> */}
                <Text
                  fontSize="26px"
                  className="ms-0"
                  fontWeight="600"
                  color="#fff"
                  textTransform="uppercase"
                  style={{ paddingLeft: "18px" }}
                >
                  {/* {levelNameMapping(+info.level)} */}
                  Chocolate
                </Text>
                <Text
                  fontSize="20px"
                  className="ms-0"
                  fontWeight="900"
                  color="#fff"
                  style={{
                    background:
                      "linear-gradient(269.99deg, #88032F 3.87%, #EE1542 87.56%)",
                    borderRadius: "8px 8px 0px 0px",
                    padding: "13px",
                  }}
                >
                  {formatTotalCoin(info?.price)} BUSD
                </Text>
              </>
            )}
          </div>
        </Flex>
      </InputTokenWrapper>
      {isTemp ? (
        <ButtonBuy>More Details</ButtonBuy>
      ) : (
        <ButtonBuy
          onClick={
            disabledBuy ||
            (info.soldCount + info.sendCount) / info.totalCount == 1 ||
            isTemp
              ? null
              : onHandleBuy
          }
        >
          BUY NOW
        </ButtonBuy>
      )}
    </IDOSwapBoard>
    // </BoardWrapper>
  );
};

export default React.memo(IDONFT);
