import { motion } from 'framer-motion';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import '../App.css';
import { AppContext } from '../AppContext';
import { BUSD_TOKEN_ADDRESS, IMAGE_BY_LEVEL, MONG_LAUNCHPAD_ADDRESS } from '../config/mongConfig';
import useDAO from '../hooks/useDAO';
import useWeb3 from '../hooks/useWeb3';
import { CloseIcon } from './Icons';
import BuyModal from './Modal/BuyModal';
import MongNFT from './MongNFT';
import ScreenBlocking from './ScreenBlocking';
import { Text } from './SwapBoard';

const MySwal = withReactContent(Swal);
const MainWrapper = styled.div`
  // min-height: calc(100vh - 100px);
  div:first-child {
    flex-wrap: wrap;
    /* margin: -1rem; */
  }
  column-gap: 30px;
`;

export const BackToHome = styled.div`
  padding: 8px 10px;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.02);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  display: inline-block;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.1);
  transition: 0.15s ease-out;
  svg {
    stroke: #3a3a3a;
    transition: 0.15s ease-out;
  }
  :hover {
    box-shadow: 0 5px 8px rgba(0, 0, 0, 0.2);
    svg {
      stroke: #333;
    }
  }
`;

export const IconWrapper = styled.div`
  p {
    transition: 0.15s ease-in-out;
    /* opacity: 0; */
  }
  :hover {
    p {
      /* opacity: 1; */
    }
  }
  max-width: 100px;
`;

const variants = {
  hidden: {
    x: '20%',
    opacity: 0,
    transition: {
      duration: 0.2,
    },
  },
  visible: {
    x: '0',
    opacity: 1,
  },
};

const HeaderBackground = styled.div`
  width: 100%;
  height: 600px;
  background: url('/images/mong_banner.png') center 35% / cover no-repeat;
`;

const MongMarket = ({ account }) => {
  const {
    web3,
    isLoading: isMainLoading,
    mongNftSell,
    listMongMyNft,
    setMongNftSell,
    loadMongMyNFT,
  } = useContext(AppContext);

  useEffect(() => {
    MySwal.fire({
      width: 1024,
      padding: 0,
      html: `
      <img src="/WINERY_MONG_1920x1080v4.png" style="width:100%;object-fit:cover;" >
      `,
      showConfirmButton: false,
      showCloseButton: true,
      closeButtonHtml: (
        <div>
          <CloseIcon style={{ color: 'white' }} width="24px" />
        </div>
      ),
    });
  }, []);

  const { onApproveHandler, checkApprove } = useWeb3(account);

  const { buyMongNFT } = useDAO();

  const [isApproved, setApproved] = useState(false);

  const [isLoading, setLoading] = useState(false);

  const [isOpenModal, setOpenModal] = useState(false);

  const [infoSelected, setSelectedInfo] = useState(false);

  const toggleModal = () => {
    setOpenModal(!isOpenModal);
  };

  useEffect(() => {
    loadAllowance();
    setOpenModal(false);
  }, [account]);

  const loadAllowance = async () => {
    const isApproved = await checkApprove(web3, BUSD_TOKEN_ADDRESS, 18, MONG_LAUNCHPAD_ADDRESS);

    if (isApproved) {
      setApproved(true);
    } else {
      setApproved(false);
    }
  };

  const onApprove = async () => {
    setLoading(true);

    const isSuccess = await onApproveHandler(web3, BUSD_TOKEN_ADDRESS, MONG_LAUNCHPAD_ADDRESS);
    setApproved(isSuccess);

    setLoading(false);
  };

  const onBuy = async ({ index, refCode, price, level }) => {
    setLoading(true);

    const isSuccess = await buyMongNFT({
      index,
      account,
      refCode,
      web3,
      price,
      level,
      contract: MONG_LAUNCHPAD_ADDRESS,
    });
    setLoading(false);

    if (isSuccess) {
      const newNftSell = [...mongNftSell];
      newNftSell[index].soldCount += 1;

      setMongNftSell(newNftSell);
      loadMongMyNFT();
      withReactContent(Swal).fire({
        imageUrl: '/mouse.svg',
        imageWidth: 'auto',
        imageHeight: 'auto',
        imageAlt: 'Custom image',

        title: <span style={{ color: 'rgba(30, 147, 255, 1)' }}>Congratulations!</span>,
        textColor: 'green',
        html: <span style={{ color: 'rgb(128, 128, 128)', fontWeight: 400 }}>You have successfully Buy NFT </span>,
        focusConfirm: false,
        confirmButtonText: 'Continue',

        backdrop: `#e7edf599`,
      });
      toggleModal();
    } else {
      withReactContent(Swal).fire({
        imageUrl: '/error-face.png',
        imageWidth: 'auto',
        imageHeight: 'auto',
        imageAlt: 'Custom image',
        title: <span style={{ color: '#ED1C51' }}> Something wrong...</span>,
        textColor: 'green',
        html: (
          <span style={{ color: 'rgb(128, 128, 128)', fontWeight: 400 }}>
            Something went wrong. Please try again!!!
          </span>
        ),
        focusConfirm: false,
        confirmButtonText: 'Continue',

        backdrop: `#e7edf599`,
      });
    }
  };

  return (
    <>
      <motion.main
        variants={variants}
        initial="hidden"
        animate="visible"
        exit="hidden"
        className="my-3 position-relative"
      >
        <HeaderBackground className="d-flex align-items-center justify-content-center">
          <div>
            <Text
              color="#fff"
              fontFamily="Playfair"
              fontWeight="900"
              fontSize="62px"
              textTransform="uppercase"
              textAlign="center"
              mb="48px"
              className="ms-0"
            >
              Mong Talent Market
            </Text>
            <Text
              color="#fff"
              textAlign="center"
              fontFamily="Playfair"
              fontSize="28px"
              fontWeight="500"
              className="ms-0"
            >
              TELL MONG YOUR WISH
            </Text>
          </div>
        </HeaderBackground>
        <MainWrapper className="container" style={{ marginBottom: 10 }}>
          <div
            style={{
              marginTop: 20,
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
            }}
          >
            <Text
              color="#000"
              fontWeight="400"
              fontSize="21px"
              style={{ lineHeight: 1.4, width: '80%', marginTop: 20 }}
              textAlign="center"
              className="ms-0"
            >
              MONG Talent Market has released its first NFT collection for DAO members to gain special offers. NFT
              owners can also have priority for the next NFT sales round.
            </Text>
            <Text
              color="#000"
              fontWeight="400"
              fontSize="21px"
              style={{ lineHeight: 1.4, width: '80%', marginTop: 20 }}
              textAlign="center"
              className="ms-0"
            >
              By purchasing WineryMongMarketNFTs, you will receive privileges on MONG Talent Market, a South East Asian
              human resource platform with over 700 million users. Additionally, Winery DAO also guarantees you a
              passive income corresponding to the NFT levels. Thus, why should you be hesitant when a lucrative
              opportunity exists?
            </Text>

            <Text
              as="div"
              color="#000"
              fontWeight="600"
              fontSize="24px"
              style={{ lineHeight: 1.4, marginTop: 20 }}
              textAlign="center"
              className="mx-auto col-md-10"
            >
              #𝘽𝙚𝙣𝙚𝙛𝙞𝙩𝙨
              <Text
                fontSize="20px"
                fontWeight="400"
                className="col-md-10 mx-auto text-center mt-2"
                style={{ lineHeight: 1.3, fontStyle: 'italic' }}
              >
                During the three-month staking, the purchaser will receive a monthly interest payment of 10% of the
                purchased amount.
                <br />
                After 3 months, the initial purchased amount of the Mong NFT Cards will be returned to the purchaser.
                <br />
                These prizes and benefits are guaranteed by the Winery, so just catch this opportunity before too late!
              </Text>
            </Text>
          </div>
        </MainWrapper>

        <MainWrapper className="container" style={{ marginBottom: 150 }}>
          <div className="d-flex mb-4">
            {mongNftSell.map((item, index) => {
              return (
                <MongNFT
                  info={{ ...item, level: item?.level }}
                  disabledBuy={!!listMongMyNft?.length}
                  onBuy={() => {
                    setSelectedInfo({
                      ...item,
                      level: item?.level,
                      index: index,
                    });
                    toggleModal();
                  }}
                />
              );
            })}
          </div>
        </MainWrapper>
      </motion.main>
      {isOpenModal && (
        <BuyModal
          title="Buy NFT"
          isActive={isOpenModal}
          info={infoSelected}
          imageUrl={IMAGE_BY_LEVEL[infoSelected.level]}
          price={+infoSelected.price}
          handleClose={toggleModal}
          index={+infoSelected.index}
          level={+infoSelected.level}
          onBuy={onBuy}
          isApproved={isApproved}
          onApprove={onApprove}
          type="mong"
        />
      )}
      <ScreenBlocking isLoading={isLoading || isMainLoading} />
    </>
  );
};

export default MongMarket;
