export const MONG_NFT_ADDRESS = process.env.REACT_APP_MONG_LAUNCHPAD_NFT_ADDRESS;
export const MONG_LAUNCHPAD_ADDRESS = process.env.REACT_APP_MONG_LAUNCHPAD_ADDRESS;
export const BUSD_TOKEN_ADDRESS = process.env.REACT_APP_BUSD_TOKEN_ADDRESS;
export const COUNT_MONG_OPTION_SELL = 3;

export const IMAGE_BY_LEVEL = {
  1: '/images/mong1.png',
  2: '/images/mong2.png',
  3: '/images/mong3.png',
};

export const levelBackgroundMapping = (level) => {
  switch (level) {
    default:
      return '#202020';
  }
};

export const levelNameMapping = (level) => {
  switch (+level) {
    case 1:
      return 'Mumphrey';
    case 2:
      return 'Mojo';
    case 3:
      return 'Minke';
    default:
      break;
  }
};

export const levelDescriptionMapping = (level) => {
  switch (+level) {
    case 1:
      return `Mumphrey is the first character designed in the WineryMongMarketNFTs collection. With the image of a successful businessman, Mumphrey's desire is also MONG's desire, “a blue hope to see you” is the constant development in his career. A well-dressed outfit stands for being mentally prepared to accept any difficulties that may arise in any situation. Just tell MONG your wish and MONG will make it come true.`;
    case 2:
      return 'Mojo is a successful gentleman whale. A whale in a suit with a confident demeanor implies that nothing can stop a great whale man from dominating the ocean or even his career. With the meaning of wishing, every talent can pursue their dream jobs, which can be decisive in not only their life but also their working passion.';
    case 3:
      return "Minke in blue is the mascot of MONG. Its color evokes in our mind a sense of calm and serenity, implying that there is no need to be concerned about entering MONG Talent Market. Moreover, Minke's shaping is reaching across the galaxy, going to the moon, demonstrating the great hope and desire of the MONG Talent Market. That is the message MONG wants to convey through its platform.";
    default:
      break;
  }
};
