import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
// import { timeCountDown } from "../hooks/useDate";

import { IMAGE_BY_LEVEL, levelBackgroundMapping, levelNameMapping } from '../config/mongConfig';

export const formatTotalCoin = (num) => {
  let dollarUSLocale = Intl.NumberFormat('en-US');

  return dollarUSLocale.format(num);
};

const IDOSwapBoard = styled.div`
  box-shadow: 4px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  background: transparent;
  max-width: 368px;
  margin: 0 auto;
  overflow: hidden;
  width: 100%;
  position: relative;
  transition: 0.2s ease-out;
  margin-top: 70px;
  cursor: pointer;
  &:hover {
    transform: scale(1.02);
    color: red;
  }
`;

export const Text = styled.p`
  margin-left: 8px;
  color: ${({ color }) => color ?? '#000'};
  font-weight: ${({ fontWeight }) => fontWeight ?? '700'};
  font-size: ${({ fontSize }) => fontSize ?? '34px'};
  ${({ textTransform }) => textTransform && `text-transform:${textTransform};`}
  ${({ fontFamily }) => fontFamily === 'Playfair' && 'font-family: Playfair Display SC;'}
  line-height: ${({ lineHeight }) => lineHeight ?? 1};
  text-align: ${({ textAlign }) => textAlign ?? 'start'};
  ${({ mb }) => mb && `margin-bottom: ${mb};`}
  ${({ truncate }) =>
    truncate &&
    `
    text-overflow:ellipsis;
    white-space:nowrap;
    overflow:hidden;
    width:150px;
    `}
    ${({ linear }) =>
    linear &&
    `
    background: linear-gradient(-90deg, rgb(136, 3, 47), rgb(238, 21, 66));
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
    `}
`;

export const StyledButton = styled.button`
  --main-color: linear-gradient(214.02deg, #d71479 6.04%, #f87421 92.95%);
  text-decoration: none;
  outline: none;
  border: none;

  background: var(--main-color);
  color: #fff;
  text-transform: capitalize;
  font-weight: 600;
  padding: 1rem;
  transition: 0.1s ease-out;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 30px;
  font-size: ${({ fontSize }) => fontSize ?? '16px'};
  &.success {
    background: var(--main-color);
    border-radius: 10px;
  }
  :disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  &:hover {
    filter: brightness(1.1);
  }
`;

export const OutlineButton = styled(StyledButton)`
  border-radius: 30px;
  background: rgb(247, 248, 250);
  position: relative;
  color: #ed1c51;
  :hover {
    color: #fff;
  }
  :before {
    border-radius: 30px;
    position: absolute;
    content: '';
    background: linear-gradient(214.02deg, #d71479 6.04%, #f87421 92.95%);
    top: -2px;
    left: -2px;
    bottom: -2px;
    right: -2px;
    position: absolute;
    z-index: -1;
  }
`;

const InputTokenWrapper = styled.div`
  text-align: left;
  padding: 0.75rem;
  /* border: 1px solid rgba(196, 196, 196, 0.5); */
`;

export const Flex = styled.div`
  display: flex;
  ${({ alignItems }) => alignItems && `align-items:${alignItems};`}
  ${({ justifyContent }) => justifyContent && `justify-content:${justifyContent};`}
  ${({ flexWrap }) => flexWrap && `flex-wrap:${flexWrap};`}
`;

export const GradientText = styled(Text)`
  background: -webkit-linear-gradient(214.02deg, #d71479 6.04%, #f87421 92.95%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const StyledNFTImg = styled.img`
  width: ${({ width }) => width ?? '100%'};
  height: auto;
  min-height: 200px;
  border-radius: 5px 5px 0 0;
`;

const NFTImage = styled(StyledNFTImg)`
  max-height: auto;
`;

const ButtonBuy = styled.div`
  background-color: white !important;
  padding: 10px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  font-size: 30px;
  line-height: 45px;
  text-align: center;
  text-transform: uppercase;
  background-color: #ffffff;
  color: #ee1542;
  cursor: pointer;
`;

const MongNFT = ({ info, onBuy, disabledBuy }) => {
  const onHandleBuy = () => {
    return onBuy();
  };

  // const [countTime, setCountTime] = useState(1664928000000 - (new Date).getTime())

  // useEffect(() => {
  //   let interval = null
  //   interval = setInterval(() => {
  //     if (countTime > 0) {
  //       setCountTime(prev => prev - 1000)
  //     } else {
  //     }
  //   }, 1000)

  //   return () => clearInterval(interval);
  // }, [countTime])

  return (
    <IDOSwapBoard
      style={{ background: levelBackgroundMapping(info.level) }}
      onClick={
        // disabledBuy || (info.soldCount + info.sendCount) / info.totalCount === 1 || countTime > 0
        disabledBuy || (info.soldCount + info.sendCount) / info.totalCount === 1 ? null : onHandleBuy
      }
    >
      <NFTImage src={IMAGE_BY_LEVEL[info.level]} />
      {disabledBuy && (
        <img
          alt="images/reachlimit.png"
          style={{
            position: 'absolute',
            top: -70,
            left: -44,
            zIndex: 1000,
            width: 220,
            transform: 'rotateZ(349deg)',
          }}
          src={`/images/reachlimit.png`}
        />
      )}
      <InputTokenWrapper className="z-100  mt-2 p-0">
        <Flex justifyContent="space-between" alignItems="end" className="mb-2 px-3">
          <Text
            fontSize="20px"
            className="ms-0"
            fontWeight="900"
            color="#ffffff"
            textTransform="uppercase"
          >{`Tier ${info.level}`}</Text>
          <Text color="#ffffff" fontSize="18px" fontWeight={500} className="ms-0">
            {`${info.soldCount + info.sendCount}/${info.totalCount}`}
          </Text>
        </Flex>
        <Flex justifyContent="space-between" alignItems="end" className="ms-0 mt-3">
          <div className="d-flex flex-row align-items-center justify-content-between w-100 mt-3">
            <Text
              fontSize="26px"
              className="ms-0"
              fontWeight="600"
              color="#fff"
              textTransform="uppercase"
              style={{ paddingLeft: '18px' }}
            >
              {levelNameMapping(+info.level)}
            </Text>
            <Text
              fontSize="20px"
              className="ms-0"
              fontWeight="900"
              color="#fff"
              style={{
                background: 'linear-gradient(269.99deg, #88032F 3.87%, #EE1542 87.56%)',
                borderRadius: '8px 8px 0px 0px',
                padding: '13px',
              }}
            >
              {info.price} BUSD
            </Text>
          </div>
        </Flex>
      </InputTokenWrapper>
      {/* <ButtonBuy
      >
        {/* {countTime > 0 ? timeCountDown(countTime / 1000) : 'BUY NOW'}
        BUY NOW
      </ButtonBuy> */}
    </IDOSwapBoard>
  );
};

export default React.memo(MongNFT);
