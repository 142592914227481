import React from "react";
import styled from "styled-components";

const Footer = () => {
  return (
    <WrapperFooter>
      <div className="logo__footer"></div>
      <div></div>
      <div></div>
      <div></div>
    </WrapperFooter>
  );
};

export default Footer;
const WrapperFooter = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
`;
