import { AnimatePresence } from 'framer-motion';
import React, { lazy, Suspense, useCallback, useContext, useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import styled from 'styled-components';
import './App.css';
import { AppContext } from './AppContext';
import GlobalStyled from './GlobalStyled';

import Footer from './components/Footer';
import { CloseIcon } from './components/Icons';
import IDOMarket from './components/IDOMarket';
import AccountModal from './components/Modal/AccountModal';
import MongMarket from './components/MongMarket';
import Navbar from './components/Navbar';

const Home = lazy(() => import('./components/Home'));
const Guide = lazy(() => import('./components/Guide'));
const Market = lazy(() => import('./components/Market'));
const MyNFT = lazy(() => import('./components/MyNFT'));
const Category = lazy(() => import('./components/Category'));

const Layout = styled.div`
  background: #fff;
  overflow: hidden;
`;

const Wrapper = styled.div`
  margin-top: 100px;
`;

function App() {
  const { account, networkId, myRefCode } = useContext(AppContext);
  const [isShowAcc, setShowAcc] = useState(false);

  const closeModalHandler = useCallback(() => setShowAcc(false), []);
  const openModalHandler = useCallback(() => setShowAcc(true), []);
  return (
    <Layout>
      <GlobalStyled />
      <Navbar account={account} networkId={networkId} openModalHandler={openModalHandler} myRefCode={myRefCode} />
      <AccountModal isShowAcc={isShowAcc} closeModalHandler={closeModalHandler} />

      <Wrapper>
        <AnimatePresence exitBeforeEnter initial={false}>
          <Suspense fallback={<div />}>
            <Routes>
              <Route path="/" element={<Market networkId={networkId} account={account} />} />
              <Route path="/market" element={<Market networkId={networkId} account={account} />} />
              <Route path="/ido-market" element={<IDOMarket networkId={networkId} account={account} />} />
              <Route path="/mong-market" element={<MongMarket networkId={networkId} account={account} />} />
              <Route path="/my-nft" element={<MyNFT />} />
              {/* <Route path="/guides" element={<Guide />} /> */}
              <Route path="/category" element={<Category />} />
            </Routes>
          </Suspense>
        </AnimatePresence>
      </Wrapper>
      <Footer />
    </Layout>
  );
}

export default App;
